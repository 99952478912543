@import '../../marketplace.css';

:root {}

.root {
  max-width: 625px;
  margin: 0 auto;
}

.inputWrapper {
  margin-top: 10px;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
}

.name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.input {
  border: solid 1px #eaeaea;
}

.firstName {
  flex-grow: 1;
  margin-right: 10px;
}

.lastName {
  flex-grow: 1;
  margin-left: 10px;
}

.submit {
  margin-top: 24px;
}

.submitButton {
  @apply --marketplaceButtonStyles;
  padding: 0;
  margin: 0 auto;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }

  transition: 0.3s all;
}

.sendingButton {
  pointer-events: none !important;
  background-color: var(--matterColorAnti) !important;
}

.successMessage {
  text-align: center;
  font-size: 15px;
  margin-top: 15px;
  color: var(--successColorDark)
}

.errorMessage {
  text-align: center;
  font-size: 15px;
  margin-top: 15px;
  color: var(--failColor)
}
