@import '../../marketplace.css';

:root {
  --ItineraryForm_formMargins: {
    margin-bottom: 24px !important;

    @media (--viewportMedium) {
      margin-bottom: 32px !important;
    }
  }
}

.root {
  color: var(--matterColorDark);
}

.error {
  color: var(--failColor);
}

.line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inLineField {
  flex-basis: 45%;
  color: var(--matterColorDark);
}

.root .inLineField select {
  /* color: var(--matterColorDark) !important; */
}

.root .inLineField select:disabled {
  background-image: none;
}

.readOnly input[type=number]::-webkit-inner-spin-button,
.readOnly input[type=number]::-webkit-outner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: block;
    width: 241px;
    margin: 100px auto 100px;
  }
}

.header {
  background-color: var(--marketplaceColor);
  & div {
    text-align: center;
    font-weight: 600;
    color: var(--matterColorLight);
    padding: 12px;
  }
}

.index {
  flex-basis: 5%;
  border-right: 1px solid var(--matterColorLight);
  text-align: left;
  padding: 10px;
  display: flex;
  align-items: center;
}

.indexTitle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lift {
  min-height: 40px;
  flex-basis: 25%;
  border-right: 1px solid var(--matterColorLight);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.run {
  flex-basis: 25%;
  min-height: 40px;
  border-right: 1px solid var(--matterColorLight);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notes {
  flex-basis: 55%;
  min-height: 40px;
  padding: 10px;
  border-right: 1px solid var(--matterColorLight);
  display: flex;
  align-items: center;
  justify-content: center;
}

.step {
  display: flex;
  flex-direction: row;
  background-color: var(--matterColorLight);

  & textarea {
    border: none;
    text-align: left;
    padding: 10px 0;
  }
}

.step:nth-child(2n + 2){
  background-color: var(--matterColorNegative);
}

.additionalNotes {
  border-right: 1px solid var(--matterColorLight);
  flex-grow: 1;
  text-align: left;
}

.additionalNote {
  flex-basis: 95%;
  padding: 10px;
}

.addNewLine {
  margin: 18px 0;
  cursor: pointer;

  color: var(--marketplaceColorDark);

  &:hover {
    text-decoration: underline;
  }
}

.goal {
  margin-bottom: 32px;
}

.root textarea, .root input {
  &:disabled {
    color: var(--matterColorDark);
  }
}

.remove {
  text-align: center;
  min-width: 120px;
  padding: 10px;
}

.removeBtn {
  cursor: pointer;
  text-align: center;
  min-width: 120px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.downloadBtn {
  @apply --marketplaceButtonStyles;
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: block;
    width: 241px;
    margin: 100px auto 100px;
  }
}
