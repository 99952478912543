@import '../../marketplace.css';

:root {
}

.root {
  width: 100%;
  margin-bottom: 12px;

  @media (--viewportLarge) {
    max-width: 993px;
    width: 993px;
    margin-bottom: 20px;
  }
}

.question {
  position: relative;
  color: #4a4a4a;
  cursor: pointer;
  padding: 11px 48px 11px 24px;
  width: 100%;
  text-align: left;
  border: 1px solid #b2b2b2;
  outline: none;
  transition: 0.4s;
}

.question::before {
  content: '';
  position: absolute;
  width: 14px;
  height: 9px;
  top: 20px;
  right: 24px;
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><path d="M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z" stroke="%23b2b2b2" fill="%23b2b2b2" fill-rule="evenodd"/></svg>');
  background-repeat: no-repeat;
  background-size: 14px 9px;
  transition: transform 0.3s ease-in;
}

.answer {
  max-height: 0px;
  white-space: pre-wrap;
  background-color: #fff;
  overflow: hidden;
  transition: 0.4s ease-in-out;
  border-right: 1px solid #b2b2b2;
  border-left: 1px solid #b2b2b2;
  border-bottom: 1px solid transparent;
}

.fullWidthImage {
  width: 100%;
  height: 100%;
}

.mobileImage {
  @media (--viewportMedium) {
    max-width: 50%;
    width: 50%;
  }
}

.halfWidthImage {
  width: 50%;
}

.answerContent {
  display: block;
  padding: 12px 24px;
  white-space: pre-wrap;
}

.isOpen {
  border-bottom: 1px solid #b2b2b2;
  max-height: unset;
}

.question_open {
  &::before {
    transform: rotate(180deg);
  }
}


.answerContent ul {
  display: inline-flex;
  flex-wrap: wrap;
  padding: 16px;
  border: 1px solid  #b2b2b2;
  
  @media (--viewportSmall) {
    width: 50%;
  }

  &:first-of-type {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;

    @media (--viewportSmall) {
      border-right: none;
      border-bottom: 1px solid #b2b2b2;
      margin-bottom: 18px;
      padding-bottom: 16px;
    }
  }

  &:last-of-type {
    border-top: none;
    margin-top: 0;
    padding-top: 0;

    @media (--viewportSmall) {
      margin-top: 18px;
      padding-top: 16px;
      border-left: none;
      border-top: 1px solid #b2b2b2;
    }
  }
}

.answerContent ul li {
  width: 100%;
}
