@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  @apply --marketplaceH4FontStyles;

  /* Since the line items have different font sizes in the label and the
   value columns, we have to balance the line to align to the
   baseline. */
  margin: -1px 0 0 0;

  @media (--viewportMedium) {
    margin: -1px 0 8px 0;

    &:last-of-type {
      /* Last item should not have bottom margin that goes to the bottom of
         the component */
      margin-bottom: 0;
    }
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.subTotalLineItem {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);

  margin: 4px 0 1px 0;

  @media (--viewportMedium) {
    margin: 6px 0 9px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.itemLabel {
  flex: 1;
  margin-top: 1px; /* align with baseline */
}

.itemValue {
  @apply --marketplaceH4FontStyles;
  font-weight: 600;
  margin: 0 0 0 10px;

  @media (--viewportMedium) {
    font-weight: 700;
    margin: 0 0 0 10px;
  }
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 13px 0 4px 0;

  border: none;
  background-color: var(--matterColorNegative);

  @media (--viewportMedium) {
    margin: 2px 0 14px 0;
  }
}

.totalLabel {
  font-weight: var(--fontWeightSemiBold);

  /* Move so that baseline aligns with the total price */
  padding-top: 6px;

  @media (--viewportMedium) {
    padding-top: 8px;
  }
}

.totalPrice {
  @apply --marketplaceBodyFontStyles;
  font-weight: var(--fontWeightSemiBold);
  margin: 0 0 0 10px;
  padding-top: 4px;

  @media (--viewportMedium) {
    margin: 2px 0 0 10px;
    padding-top: 0;
  }
}

.feeInfo {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  margin: 0 0 20px 0;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 22px;
  }
}
