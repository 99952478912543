
@import '../../marketplace.css';

.root {
    width: 100%;
    min-width: 200px;

    @media (--viewportMedium) {
        min-width: 400px;
    }
}

.nested {
    padding-left: 3em !important;
}

.header_container {
    padding: 1.4em;
    background-color: var(--marketplaceColor);
}

.header_container h2 {
    margin: 0;
    color: var(--matterColorLight);
    text-align: center;
}

.primary span {
    color: blue !important;
}

.blockedTime span {
    color: var(--matterColorDark) !important;
}

.secondary span {
    color: var(--failColor) !important;
}

.container {
    padding: 0 0 1.4em 0;
}

.container .saveButton {
    width: 250px;
    background-color: var(--marketplaceColor) !important;
    border-radius: none !important;
}
