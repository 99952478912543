@import '../../marketplace.css';

:root {}

.root {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;
  padding: 0 12px 24px 12px;

  box-shadow: var(--boxShadowBreakdown);
  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 72px auto;
  }
}

.content {
  width: 100%;
}

.heading {
  text-align: center;
}