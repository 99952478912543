@import '../../marketplace.css';

:root {
  --colorPalete: {
    width: 50px;
    height: 15px;
    margin-right: 10px;
  }
}

.root {
  background-color: var(--matterColorLight);
}

.noListing {
  text-align: center;
  padding-top: 1em;
}

.timezoneText {
  padding-left: 15%;
}

.content {
  width: calc(100% - 48px);
  margin: 12px 24px 24px 24px;
  @media (--viewportMedium) {
    max-width: 565px;
    margin: 55px auto 56px auto;
  }
}

.headingContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.errorHeading {
  color: var(--failColor);
  margin: 13px 0 22px 0;

  @media (--viewportMedium) {
    margin: 13px 0 23px 0;
  }

  @media (--viewportLarge) {
    margin: 12px 0 23px 0;
  }
}

.heading {
  margin-right: 24px;
}

.profileLink {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceH5FontStyles;
  display: inline-block;
  width: auto;
  min-height: auto;
  height: 41px;
  float: right;
  flex-shrink: 0;
  margin: 19px 0 0 auto;
  padding: 9px 16px 8px 16px;
  @media (--viewportMedium) {
    margin: 37px 0 0 auto;
  }
}

.pickers {
  width: 100%;
}

/* Calendar */

.calendarContainer {
  width: 100%;

  @media (--viewportLarge) {
    width: fit-content;
  }
}

.calendarContainer abbr {
  text-decoration: none !important;
}

.calendarContainer>div {
  border: none !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 1em;
  width: 100% !important;

  @media (--viewportMedium) {
    width: 400px !important;
  }
}

/* Custom */

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100% !important;
  /* align-items: center; */
  justify-content: center;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: auto 0 !important;
  align-self: center !important;
}

.left {
  width: 100px;
  display: flex;
  flex-direction: column;
  padding-bottom: 3em;
  margin: auto;

  @media (--viewportLarge) {
    width: 50%;
    margin: 0;
    padding-left: 70px;
  }

  @media screen and (min-width: 1200px) {
    padding-left: 15%;
  }
}

.calendarContainer > div {
  margin: auto !important;
}

.header_container {
  padding: 1em 0;
}

.right {
  width: 100px;
  padding: 50px 0px;
  margin: 0 auto;

  @media (--viewportLarge) {
    width: auto;
    margin-right: 0;
    margin-left: auto;
    height: 100%;
  }
}

.right > div > header {
  height: auto !important;
}

.right > div {
  width: 100% !important;

  @media (--viewportLarge) {
    width: 500px !important;
  }
}

.txBoxes {
  margin: 30px auto;
  width: 100%;

  @media (--viewportMedium) {
    margin: 50px auto;
  }

  @media (--viewportLarge) {
    margin: 50px 0px;
  }
}

.txItem {
  display: flex;
  flex-direction: row;

  /* Layout */
  margin-bottom: 10px;
  padding-bottom: 12px;

  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--matterColorNegative);

  &:last-child {
    border-bottom: none;
  }

  @media (--viewportLarge) {
    margin-bottom: 21px;
    padding-bottom: 18px;
  }
}

.txInfo {
  display: block;
}

.txStatus {
  margin-left: auto;
}

.buttonAccept {
  margin-top: 6px;
  min-height: 0px;
  padding: 7px 12px;
  border-radius: 5px;
  background-color: var(--successColor);
  color: var(--matterColorLight);
  font-size: 20px;
  cursor: pointer;

  &:hover {
    background-color: var(--successColorDark);
  }
}

.txGuestAvatar {
  margin-right: 8px;

  @media (--viewportLarge) {
    margin-top: 2px;
    margin-right: 15px;
  }
}

.stateName {
  /* This class is empty on purpose, it is used below for banned users */
}

.stateEnquired,
.stateRequested,
.statePending {
  font-weight: var(--fontWeightSemiBold);
  color: var(--attentionColor);
}

.stateAccepted {
  font-weight: var(--fontWeightSemiBold);
  color: var(--successColor);
}

.stateCanceled,
.stateDeclined,
.stateDelivered {
  color: var(--matterColorAnti);
}

.choosedDate {
  background-color: red;
}

.requestedDate {
  position: relative;
}

.requestedDate:after {
  background-color: var(--attentionColor);
  position: absolute;
  content: '';
  height: 8px;
  right: 0;
  left: 0;
  top: 0;
}

.acceptedDate {
  position: relative;
}

.acceptedDate::after {
  background-color: var(--successColor);
  position: absolute;
  content: '';
  height: 8px;
  right: 0;
  left: 0;
  top: 0;
}

.combinedDate {
  position: relative;
}

.combinedDate::after {
  background: linear-gradient(to right, var(--attentionColor) 50%, var(--successColor) 50%);
  position: absolute;
  content: '';
  height: 8px;
  right: 0;
  left: 0;
  top: 0;
}

.calendarDate {
  border: 1px solid var(--matterColorNegative) !important;
}

.colorDescription {
  display: flex;
  justify-content: space-between;
}

.colorDescriptionItem {
  padding: 10px 0px;
  flex-basis: 48%;
  display: flex;
  align-items: center;
}

.colorPalete1 {
  @apply --colorPalete;
  background-color: var(--attentionColor);
}

.colorPalete2 {
  @apply --colorPalete;
  background-color: var(--successColor);
}

.descriptionPalete {
  font-size: 16px;
}