@import '../../marketplace.css';

:root {

}

.root {

}

.pageContent {
    margin: 40px 24px 51px 24px;

    @media (--viewportMedium) {
      max-width: 100%;
      margin: 60px 24px 60px 24px;
    }
  
    @media (--viewportLarge) {
      max-width: 993px;
      padding: 0 36px 0 36px;
      margin: 94px auto 93px auto;
    }
}