@import '../../marketplace.css';

:root {
  --EditListingAgreementForm_formMargins: {
    margin-bottom: 24px !important;

    @media (--viewportMedium) {
      margin-bottom: 32px !important;
    }
  }
}

.root {
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  padding-top: 1px;
}

.error {
  color: var(--failColor);
}

.agreement {
  text-align: justify;
}

.paragraph {
  font-size: 21px;
  line-height: 28px;
}

.title {
  font-size: 32px;
  font-weight: 600;
  margin-top: 48px;
}

.requirements {
  text-align: justify;
}

.requirement {
  font-size: 21px;
  line-height: 28px;
  padding-left: 48px;
  position: relative;
}

.one {
  &:before {
    content: '1.';
    position: absolute;
    left: 10px;
  }
}

.two {
  &:before {
    content: '2.';
    position: absolute;
    left: 10px;
  }
}

.three {
  &:before {
    content: '3.';
    position: absolute;
    left: 10px;
  }
}

.four {
  &:before {
    content: '4.';
    position: absolute;
    left: 10px;
  }
}

.five {
  &:before {
    content: '5.';
    position: absolute;
    left: 10px;
  }
}

.six {
  &:before {
    content: '6.';
    position: absolute;
    left: 10px;
  }
}

.label {
  font-size: 21px;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
