@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
}

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: 60vh;
  max-height: 600px;
  padding: 0;

  @media (--viewportMedium) {
    min-height: 500px;
    height: 70vh;
    max-height: none;
  }

  @media (--viewportLarge) {
    max-height: 800px;
    min-height: 600px;
    height: calc(70vh - var(--topbarHeightDesktop));
  }
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 32px;

  @media (--viewportMedium) {
    padding-bottom: 83px;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 60px;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: auto;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 3vh;
}

/* A bar on top of light sections */
.section:nth-of-type(n + 1) .sectionContent::before {
  background: var(--marketplaceColor);
  content: '';
  display: block;
  width: 109px;
  height: 6px;

  /* Place the bar on top of .sectionContent top margin */
  position: relative;
  top: calc(-1 * var(--LandingPage_sectionMarginTop));

  @media (--viewportMedium) {
    width: 192px;
    height: 8px;
    top: calc(-1 * var(--LandingPage_sectionMarginTopMedium));
  }

  @media (--viewportLarge) {
    top: calc(-1 * var(--LandingPage_sectionMarginTopLarge));
  }
}

.review {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.reviewContent {
  flex: 1;
  white-space: pre-wrap;
  text-align: center;

  @media (--viewportMedium) {
    line-height: 28px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.root :global(.slick-slide) {
  @media (--viewportLarge) {
    padding: 0 20px;
    &:nth-of-type(1) {
      padding-right: 20px;
      padding-left: 0px;
    }
    &:last-of-type {
      padding-left: 20px;
      padding-right: 0px;
    }
  }
}

.stars {
  margin-bottom: 12px;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 12px;
}

.expert,
.resort {
  color: var(--matterColorDark);
  font-weight: var(--fontWeightSemiBold);
}

.root :global(.slick-arrow) {
  position: absolute;
  top: 50%;
  background-color: transparent;
  color: transparent;
  height: 55px;
  border: none;
  outline: none;
  width: 50px;
  padding: 0;
}

.root :global(.slick-prev) {
  left: -30px;
  background-image: url('../../assets/prev.png');
  outline: none;
}

.root :global(.slick-next) {
  right: -50px;
  background-image: url('../../assets/next.png');
}

.root :global(.slick-disabled) {
  opacity: 0.5;
}

.sectionReviewTitle {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
}

.dots {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: center;

  /* &::before {
    content: ' ';
    width: 20px;
    height: 20px;
    background-color: red;
  }
  &::after {
    content: ' ';
    width: 20px;
    height: 20px;
    background-color: red;
  } */
}

.dots li {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--marketplaceColor);
  margin: 0 5px;
  opacity: 0.5;
}

.root :global(.slick-active) {
  opacity: 1;
}

.dots li > button {
  display: none;
}

.customButton {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
