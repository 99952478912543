@import '../../marketplace.css';

:root {
  --EditListingSpecialtiesForm_formMargins: {
    margin-bottom: 24px !important;

    @media (--viewportMedium) {
      margin-bottom: 32px !important;
    }
  }
}

.root {
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  padding-top: 1px;
}

.error {
  color: var(--failColor);
}


.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.field {
  margin: 10px 0;

  @media (--viewportMedium) {
    margin: 20px 0;
  }
}
