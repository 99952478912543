@import '../../marketplace.css';

:root {

}

.root {
  width: 100%;
  padding: 0px 24px 24px;

  @media (--viewportLarge){
    max-width: 80%;
    display: block;
    margin: 0 auto;
    padding: 0px 0px 50px;
  }
}

.pageTitle {
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);

  @media (--viewportLarge) {
    font-size: 30px;
    line-height: 46px;
  }

  @media (--viewportLargeWithPaddings) {
    font-size: 36px;
  }
}

.heroContainer {
  background-image: url('../../assets/background-1440.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  height: 250px;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 40px;

  @media (--viewportLarge) {
    height: 20vh;
    min-height: 250px;
    margin-left: -12.5%;
    margin-right: -12.5%;
  }
}

.root :global(.DateInput_input) {
  background-color: var(--matterColorLight);
}