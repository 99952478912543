@import '../../marketplace.css';

.container {
    display: flex !important;
    flex-direction: row !important;
    /* align-items: center; */
    /* justify-content: space-between !important; */
    width: 100% !important;
    padding: 0 1.7em !important;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;

    @media (--viewportMedium) {
        flex-wrap: nowrap;
        justify-content: center;
        margin-bottom: 0px;
    }
}

.textField {
    width: 120px !important;
    /* margin: 0 !important; */
    /* padding: 0 !important; */
    margin-bottom: 1em !important;
    margin-right: 10px !important;
    flex-basis: calc(45% - 10px);

    @media (--viewportMedium) {
        margin-right: 2em !important;
        flex-basis: unset;
    }
}

.schedule {
    @apply --defaultChip;
    color: blue;
    border: 1px  var(--successColor) dotted;
    
}

.custom {
    @apply --defaultChip;
    color: blue;
    border: 1px var(--attentionColor) dotted;
}


.delete {
    @apply --defaultChip;
    color: var(--failColor);
    border: 1px var(--failColor) dotted;
}

.unavailableTime {
    @apply --defaultChip;
    color: var(--matterColor);
    border: 1px var(--matterColor) dotted;
}